/* windicss layer base */

/* windicss layer components */
.color-transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
}
.btn-icon {
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  -o-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 200ms;
  -o-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
}
.dark .btn-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 49, 79, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(124, 77, 255, var(--tw-text-opacity));
}
.btn-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity));
  border-radius: 8px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 34px;
  font-size: 16px;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(94, 53, 177, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 34px;
}
.btn-icon:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(237, 231, 246, var(--tw-text-opacity));
}
.dark .bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 25, 54, var(--tw-bg-opacity));
}
.light .bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .bg-container {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 34, 63, var(--tw-bg-opacity));
}
.light .bg-container {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 246, var(--tw-bg-opacity));
}
.bg-container {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 246, var(--tw-bg-opacity));
}
.dark .text-primary {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.light .text-primary {
  --tw-text-opacity: 1;
  color: rgba(18, 25, 38, var(--tw-text-opacity));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgba(18, 25, 38, var(--tw-text-opacity));
}
/* windicss layer utilities */
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.dark .dark\:bg-\[rgb\(33\2c 41\2c 70\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 41, 70, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.bg-\[rgb\(227\2c 242\2c 253\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity));
}
.bg-\[rgb\(94\2c 53\2c 177\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(94, 53, 177, var(--tw-bg-opacity));
}
.dark .dark\:bg-\[rgb\(189\2c 200\2c 240\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 200, 240, var(--tw-bg-opacity));
}
.bg-\[rgb\(227\2c 232\2c 239\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 232, 239, var(--tw-bg-opacity));
}
.bg-\[rgb\(30\2c 136\2c 229\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 136, 229, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.dark .dark\:bg-\[rgb\(209\2c 196\2c 233\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity));
}
.bg-\[rgb\(237\2c 231\2c 246\)\] {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity));
}
.bg-transparent {
  background-color: transparent;
}
.bg-center {
  background-position: center;
}
.bg-cover {
  background-size: cover;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-\[27px\] {
  border-radius: 27px;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.box-border {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.cursor-pointer {
  cursor: pointer;
}
.inline {
  display: inline;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.hidden {
  display: none;
}
.basis-\[48px\] {
  flex-basis: 48px;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.h-full {
  height: 100%;
}
.h-2 {
  height: 0.5rem;
}
.h-\[184px\] {
  height: 184px;
}
.h-\[80px\] {
  height: 80px;
}
.h-\[50px\] {
  height: 50px;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[241px\] {
  height: 241px;
}
.h-\[550px\] {
  height: 550px;
}
.h-200px {
  height: 200px;
}
.h-\[480px\] {
  height: 480px;
}
.h-\[150px\] {
  height: 150px;
}
.h-\[500px\] {
  height: 500px;
}
.h-\[350px\] {
  height: 350px;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-\[20px\] {
  font-size: 20px;
  line-height: 1;
}
.text-\[22px\] {
  font-size: 22px;
  line-height: 1;
}
.text-\[14px\] {
  font-size: 14px;
  line-height: 1;
}
.text-\[16px\] {
  font-size: 16px;
  line-height: 1;
}
.text-\[30px\] {
  font-size: 30px;
  line-height: 1;
}
.text-\[18px\] {
  font-size: 18px;
  line-height: 1;
}
.text-\[12px\] {
  font-size: 12px;
  line-height: 1;
}
.m-680-318\.6c0-169\.3 {
  margin: 42.325rem;
}
.m-332\.8-226\.133333h192l-98\.133334-192-93\.866666 {
  margin: 23.466667rem;
}
.m-0 {
  margin: 0px;
}
.m-4 {
  margin: 1rem;
}
.my-\[16px\] {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-\[80px\] {
  margin-top: 80px;
}
.mt-\[28px\] {
  margin-top: 28px;
}
.mt-\[50px\] {
  margin-top: 50px;
}
.ml-\[12px\] {
  margin-left: 12px;
}
.ml-\[6px\] {
  margin-left: 6px;
}
.ml-\[8px\] {
  margin-left: 8px;
}
.mt-\[20px\] {
  margin-top: 20px;
}
.mt-\[12px\] {
  margin-top: 12px;
}
.mt-\[8px\] {
  margin-top: 8px;
}
.mt-\[24px\] {
  margin-top: 24px;
}
.mt-\[10px\] {
  margin-top: 10px;
}
.mr-\[8px\] {
  margin-right: 8px;
}
.mt-\[6px\] {
  margin-top: 6px;
}
.mt-22vh {
  margin-top: 22vh;
}
.mt-\[16px\] {
  margin-top: 16px;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.opacity-\[0\.2\] {
  opacity: 0.2;
}
.dark .dark\:opacity-\[0\.2\] {
  opacity: 0.2;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}
.p-\[16px\] {
  padding: 16px;
}
.p-\[32px\] {
  padding: 32px;
}
.p-\[24px\] {
  padding: 24px;
}
.p-30px {
  padding: 30px;
}
.p-20px {
  padding: 20px;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}
.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}
.py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-\[24px\] {
  padding-right: 24px;
}
.pl-\[20px\] {
  padding-left: 20px;
}
.pl-\[10px\] {
  padding-left: 10px;
}
.pr-\[14px\] {
  padding-right: 14px;
}
.pt-\[16px\] {
  padding-top: 16px;
}
.pt-2 {
  padding-top: 0.5rem;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.bottom-15 {
  bottom: 3.75rem;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.bottom-5 {
  bottom: 1.25rem;
}
.bottom-0 {
  bottom: 0px;
}
.top-0 {
  top: 0px;
}
.top-\[80px\] {
  top: 80px;
}
.top-\[24px\] {
  top: 24px;
}
.right-\[24px\] {
  right: 24px;
}
.bottom-\[12px\] {
  bottom: 12px;
}
.resize {
  resize: both;
}
.text-right {
  text-align: right;
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.dark .dark\:text-\[rgb\(30\2c 136\2c 229\)\] {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity));
}
.text-\[rgb\(30\2c 136\2c 229\)\] {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity));
}
.dark .dark\:text-\[rgb\(227\2c 242\2c 253\)\] {
  --tw-text-opacity: 1;
  color: rgba(227, 242, 253, var(--tw-text-opacity));
}
.text-\[rgb\(179\2c 157\2c 219\)\] {
  --tw-text-opacity: 1;
  color: rgba(179, 157, 219, var(--tw-text-opacity));
}
.text-\[rgba\(229\2c 224\2c 216\2c 0\.45\)\] {
  --tw-text-opacity: 0.45;
  color: rgba(229, 224, 216, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgba\(229\2c 224\2c 216\2c 0\.85\)\] {
  --tw-text-opacity: 0.85;
  color: rgba(229, 224, 216, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-\[rgb\(132\2c 146\2c 196\)\] {
  --tw-text-opacity: 1;
  color: rgba(132, 146, 196, var(--tw-text-opacity));
}
.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-\[rgb\(18\2c 25\2c 38\)\] {
  --tw-text-opacity: 1;
  color: rgba(18, 25, 38, var(--tw-text-opacity));
}
.dark .dark\:text-\[rgb\(215\2c 220\2c 236\)\] {
  --tw-text-opacity: 1;
  color: rgba(215, 220, 236, var(--tw-text-opacity));
}
.text-\[rgb\(144\2c 202\2c 249\)\] {
  --tw-text-opacity: 1;
  color: rgba(144, 202, 249, var(--tw-text-opacity));
}
.text-\[rgb\(101\2c 31\2c 255\)\] {
  --tw-text-opacity: 1;
  color: rgba(101, 31, 255, var(--tw-text-opacity));
}
.text-\[rgb\(66\2c 66\2c 66\)\] {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity));
}
.dark .dark\:text-\[rgb\(189\2c 200\2c 240\)\] {
  --tw-text-opacity: 1;
  color: rgba(189, 200, 240, var(--tw-text-opacity));
}
.text-\[rgb\(54\2c 65\2c 82\)\] {
  --tw-text-opacity: 1;
  color: rgba(54, 65, 82, var(--tw-text-opacity));
}
.text-\[rgb\(0\2c 200\2c 83\)\] {
  --tw-text-opacity: 1;
  color: rgba(0, 200, 83, var(--tw-text-opacity));
}
.text-\[rgb\(216\2c 67\2c 21\)\] {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity));
}
.indent {
  text-indent: 1.5rem;
}
.content-distribution {
  content: "distribution";
}
.w-full {
  width: 100%;
}
.w-\[100\%\] {
  width: 100%;
}
.w-\[400px\] {
  width: 400px;
}
.w-\[160px\] {
  width: 160px;
}
.w-\[75px\] {
  width: 75px;
}
.w-\[90\%\] {
  width: 90%;
}
.z-998 {
  z-index: 998;
}
.z-999 {
  z-index: 999;
}
.z-1 {
  z-index: 1;
}
.z-10 {
  z-index: 10;
}
.gap-\[16px\] {
  grid-gap: 16px;
  gap: 16px;
}
.gap-\[4px\] {
  grid-gap: 4px;
  gap: 4px;
}
.gap-\[24px\] {
  grid-gap: 24px;
  gap: 24px;
}
.blur\(4px\) {
  --tw-blur: blur(8px);
}
@media (min-width: 1024px) {
  .lg\:hidden {
    display: none;
  }
}
@media (max-width: 1023.9px) {
  .\<lg\:hidden {
    display: none;
  }
}