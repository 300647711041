* {
  margin: 0;
}
.ant-menu-item {
  height: 50px !important;
  line-height: 50px !important;
}
.ant-menu-submenu-title {
  height: 50px !important;
  line-height: 50px !important;
}
.ant-menu-item span {
  transition: none !important;
}
.ant-menu-item:hover {
  color: rgb(124, 77, 255) !important;
  background-color: #f0e9f7 !important;
}
.light .ant-menu-item:hover {
  color: rgb(124, 77, 255) !important;
  background-color: #f0e9f7 !important;
}
.dark .ant-menu-item:hover {
  color: rgb(124, 77, 255) !important;
  background-color: rgba(124, 77, 255, 8.2%) !important;
}
.dark .ant-menu-item-selected {
  color: rgb(124, 77, 255) !important;
  background-color: rgba(124, 77, 255, 8.2%) !important;
}
.ant-menu-submenu-title:hover {
  color: rgb(124, 77, 255) !important;
  background-color: #f0e9f7 !important;
}
.light .ant-menu-submenu-title:hover {
  color: rgb(124, 77, 255) !important;
  background-color: #f0e9f7 !important;
}
.dark .ant-menu-submenu-title:hover {
  color: rgb(124, 77, 255) !important;
  background-color: rgba(124, 77, 255, 8.2%) !important;
}
.dark .ant-menu-item-selected {
  color: rgb(124, 77, 255) !important;
  background-color: rgba(124, 77, 255, 8.2%) !important;
}
.ant-menu-inline-collapsed .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: rgb(124, 77, 255) !important;
  background-color: rgba(124, 77, 255, 8.2%) !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-selected {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-open .ant-menu-submenu-title {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-item-icon {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-submenu-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.dark .ant-menu-submenu-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.light .ant-menu-submenu-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.dark .ant-menu-title-content a {
  color: #ffffff !important;
}
.dark .ant-menu-item-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-title-content a {
  color: rgba(0, 0, 0, 88%) !important;
}
.light .ant-menu-item-selected .ant-menu-title-content a {
  color: rgb(124, 77, 255) !important;
}
.light .ant-menu-title-content a {
  color: rgba(0, 0, 0, 88%) !important;
}
.ant-menu-title-content:hover a {
  color: rgb(124, 77, 255) !important;
}
.ant-menu-sub {
  background-color: transparent !important;
}
body.dark {
  background-color: rgb(17, 25, 54);
}
.ant-btn-primary {
  box-shadow:
    rgba(0, 0, 0, 20%) 0 3px 1px -2px,
    rgba(0, 0, 0, 14%) 0 2px 2px 0,
    rgba(0, 0, 0, 12%) 0 1px 5px 0 !important;
}
.ant-menu-item .ant-menu-item-icon {
  font-size: 18px !important;
  vertical-align: middle !important;
}
